.filter-controls {
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
}

.po_items_ho {
    display:flex;
    flex-wrap: wrap;
    justify-content:first baseline;
}

.po_item {
    display: inline-block;
    width: 100%;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    padding: 6px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    min-height: 500px;
}


.po_item img {
    max-width: 100%;
}

.po_item .media {
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
}

.po_item .media {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .media {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
}

.po_item:hover .media {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .media a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .media a:hover {
    text-decoration: none;
}

@media (max-width: 991.98px) {
    .po_items_ho {
        display:flex;
        flex-wrap: wrap;
        justify-content:center;
    }
}