.card {
    margin: 1rem 1rem;
    padding: 1rem;
    width: 250px;
    box-shadow: 0 0 28px 4px rgba(0,0,0,0.1);
    transition: transform .3s ease;
    background-color: var(--background-color);
    min-height: 250px;
    display: flex;
}

@media (max-width:991.98px) {
    .card {
        width: 300px;
        height: 300px;
    }
}

.card:hover {
    transform: scale(1.1);
}

.card__body {
    margin-bottom: auto;
}

.card__media {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 0%;
    overflow: hidden;
    object-fit: contain;
}

.card__media img {
    height: 100%;
    object-fit: cover;
}

.card__media audio {
    height: 25px;
    width: 100%;
  }
