.external-link-btn {
    background-color: #4caf50; /* Green background */
    color: var(--secondary-color); /* White text */
    padding: 10px 20px; /* Padding around text */
    text-align: center; /* Center text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Allow width and height */
    font-size: 16px; /* Text size */
    margin: 4px 2px; /* Margin around button */
    cursor: pointer; /* Cursor indicates clickable */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s, transform 0.2s; /* Smooth background and transform transition */
  }
  
  .external-link-btn:hover {
    background-color: #45a049; /* Darker shade of green */
    transform: translateY(-2px); /* Move button up slightly */
  }
  
  .external-link-btn:active {
    transform: translateY(1px); /* Slightly down on click */
  }
  
  .link-icon {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s; /* Smooth transition for icon */
  }
  
  .external-link-btn:hover .link-icon {
    transform: translateY(-2px); /* Move icon to the right */
  }
  