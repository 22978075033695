.audio-player {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .play-pause-btn {
    background: linear-gradient(145deg, var(--text-color-3), #c471ed);
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 24px;
    color: white;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease-in-out, transform 0.2s ease;
  }
  
  .play-pause-btn:hover {
    background: linear-gradient(145deg, #c471ed, #6e7ff3);
    transform: scale(1.2);
  }
  
  .play-pause-btn:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.98);
  }
  
  .play {
    color: lightgreen;
  }
  
  .pause {
    color: lightcoral;
  }