section {
    flex: 1 0 auto;
    position: relative;
    width: 100%;
    -webkit-transition: all .5s ease-in;
    -o-transition: all .5s ease-in;
    transition: all .5s ease-in;
}

.who_am_I {
    font-family: Cinzel;
}


.has-first-color {
    color: var(--primary-color);
}

.btn-portfolio {
    background: var(--primary-color);
    border-radius: 0;
}

.btn-portfolio a {
    color: #000;
    text-decoration: none;
}

.btn-about a {
    color: var(--text-color);
    text-decoration: none;
}

.intro_sec {
    height: calc(100vh - 60px);
    min-height: 100vh;
    height: 100vh;
    margin-top: -60px;
}

.intro_sec .text h1 {
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 700;
}

@media (max-width:991.98px) {
    .intro_sec {
        display: block;
        height: auto!important;
    }
}

.intro_sec .text,
.intro_sec .h_bg-image {
    width: 50%;
}

@media (max-width:991.98px) {
    .intro_sec .text,
    .intro_sec .h_bg-image {
        width: 100%;
    }
}

.intro_sec .intro {
    max-width: 450px;
    margin: 0 auto;
}

@media (max-width:991.98px) {
    .intro_sec .intro {
        max-width: 700px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .intro_sec .text h1 {
        font-size: 22px;
        margin-bottom: 25px;
        font-weight: 700;
}
    .intro_sec .text h2 {
        font-size: 18px;
}
}

.intro_sec .intro .feature .wrap-icon {
    background: 0 0!important;
    width: auto;
    height: auto;
    margin-bottom: 0;
}

.intro_sec .intro .feature .wrap-icon svg {
    color: #5cccc9;
}


.intro_sec .text h3 {
    font-size: 16px;
    font-weight: 700;
}

.intro_sec .h_bg-image {
    background-size: cover;
    background-position: center;
    min-height: 700px;
    position: relative;
}

.ac_btn {
    padding: 4px 19px;
    color: var(--secondary-color);
    position: relative;
    border: var(--secondary-color) 2px solid;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    cursor: pointer;
    border-radius: 0;
    margin-right: 20px;
}

.ac_btn a {
    text-decoration: none;
}

.ac_btn:hover {
    box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color);
}

.ac_btn:hover .one {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .two {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .three {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .four {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn .ring {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    transform: translate3d(0px, 90px, 0px);
}

.ac_btn .one {
    background-color: #000;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
    z-index: -4;
}

.ac_btn .two {
    background-color: var(--primary-color);
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

.ac_btn .three {
    background-color: var(--secondary-color);
    z-index: -2;
    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

#button_p {
    background: var(--secondary-color);
    color: var(--primary-color);
}

#button_h:hover {
    color: var(--primary-color)
}

.intro_sec .h_bg-image img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    filter: drop-shadow(0px 0rem 1rem #383838) saturate(2)
}

@media (max-width:991.98px) {
    .intro_sec .h_bg-image {
        height: 100%;
        min-height: 10vh;
    }
}

.floating {  
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}
 
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 0.5rem); }
    100%   { transform: translate(0, -0px); }    
}
